<template>
    <TheInvitationModal message="Accepting Invitation" :show="show" />
</template>
<script>
const TheInvitationModal = () => import('../components/TheInvitationModal')

export default {
    mounted() {
        this.show = true
        this.$axios
            .get(`/invitations/accept/${this.$route.params.invid}`, {
                timeout: 300000
            })
            .then(response => {
                this.$store.dispatch('showSnackBar', {
                    snackBarText: 'Invitation accepted!',
                    snackBarTimeout: 5000,
                    snackBarIcon: 'check_circle'
                })
                this.show = false
                if (response.data.landing_page) {
                    const overviewURI = response.data.landing_page.split('nuvolos.cloud').pop()
                    const dashboardOid = overviewURI.split('/')[2]
                    this.$router.push({ name: 'home-dashboard', params: { oid: dashboardOid }, query: { invitation_target: overviewURI } })
                } else {
                    this.$router.push({ name: 'welcome' })
                }
                // get new orgs in case invite added a new org
                this.$store.dispatch('fetchUserOrgs')
            })
            .catch(error => {
                if (!error.response || !error.response.data || error.response.data.code !== 'already_accepting') {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Failed to accept the invitation.',
                        snackBarTimeout: 10000,
                        snackBarIcon: 'error'
                    })
                }
                this.show = false
            })
    },
    components: { TheInvitationModal },
    data() {
        return {
            show: false
        }
    }
}
</script>
